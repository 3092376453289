/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputIncreaseDecrease } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { currencySymbol } from '../../../utils/helpers';
import subscription from '../../../utils/api/subscription';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function DisplayIncreaseModal(props) {
  const dispatch = useDispatch();
  const {
    submitDeviceCount,
    closeModal,
    deviceAdd,
    totalDevice,
    currency,
    currentUsedDevice,
    viewSummary,
  } = props;

  const { t } = useTranslation();
  const [deviceCount, setDeviceCount] = useState(deviceAdd);
  const [disable, setDisable] = useState(true);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleInputChange = (name, changeValue) => {
    if (!loadingPrice) {
      if (changeValue < currentUsedDevice) {
        dispatch(setErrorNotification({
          message: 'The device count cannot be reduced below the current device count.',
        }));
        return;
      }
      setLoadingPrice(true);
      const req = {
        deviceCountChange: changeValue - totalDevice,
      };
      subscription.calculatePrice(req)
        .then((res) => {
          setLoadingPrice(false);
          setDeviceCount((prevValue) => ({
            ...prevValue,
            [name]: changeValue - totalDevice,
            totalDevice: changeValue,
            price: res?.data?.additionalDevicePrice,
            unitPrice: res?.data?.perDevicePrice,
          }));
        })
        .catch((error) => {
          setLoadingPrice(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };

  const back = () => closeModal(false);

  const submitDevice = () => {
    if (deviceCount.totalDevice > 0) {
      setButtonLoading(true);
      subscription.deviceIncreaseCountSummary({ device: deviceCount.totalDevice })
        .then((response) => {
          setButtonLoading(false);
          const payment = {
            planId: response?.data?.subscription?.plan?._id,
            tenure: response?.data?.subscription?.tenure,
            planName: response?.data?.plan_name,
            unitPrice: response?.data?.unit_price,
            deviceCount: response?.data?.device_count,
            totalPrice: response?.data?.total_price,
            paymentSummary: response?.data?.payment_summary,
            actualPrice: response?.data?.actual_price,
          };
          if (payment?.totalPrice > 0) {
            submitDeviceCount(deviceCount, payment);
          } else {
            viewSummary(deviceCount, payment);
          }
        })
        .catch((error) => {
          setButtonLoading(false);
          dispatch(setErrorNotification(error?.response?.data));
          // deviceModal(deviceCount);
        });
    } else {
      setDisable(true);
    }
  };

  useEffect(() => {
    if ((deviceCount.totalDevice > 0 && totalDevice !== deviceCount.totalDevice)
      && (deviceCount.totalDevice > currentUsedDevice)) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [deviceCount]);

  return (
    <div className="display-information">
      <div className="display-form">
        <div className="field">
          <InputIncreaseDecrease
            icon={' '}
            label={t('device')}
            type="number"
            name="device"
            required={false}
            value={deviceCount.totalDevice}
            change={handleInputChange}
            placeholder="0"
            min={0}
          />
        </div>
        <div className="field-price">
          {deviceCount.device} * {currencySymbol(currency, deviceCount.unitPrice)}/{t('device')} =
          {currencySymbol(currency, deviceCount.price)}
        </div>
      </div>
      {
        deviceCount.device < 0 && (
          <div className="down-message">
            {t('ifaDowngradeWasRequested')}
          </div>
        )
      }
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button">
          <Button label={t('back')} click={back} classes="default-button" />
        </div>
        <div className="form-button">
          <Button
            label={t('confirm')}
            click={submitDevice}
            classes={buttonLoading
              ? 'success-button loading-btn'
              : 'success-button'}
            disabled={disable}
            loading={buttonLoading}
          />
        </div>
      </div>
    </div>
  );
}

DisplayIncreaseModal.propTypes = {
  submitDeviceCount: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  totalDevice: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  currentUsedDevice: PropTypes.number.isRequired,
  deviceAdd: PropTypes.shape({
    device: PropTypes.number,
    totalDevice: PropTypes.number,
    price: PropTypes.number,
  }),
  viewSummary: PropTypes.func,
};
DisplayIncreaseModal.defaultProps = {
  deviceAdd: {},
  viewSummary: () => { },
};

export default DisplayIncreaseModal;
